@import 'components/mixins.scss';

.ant-table-cell {
  font-size: 1rem;
}

.ant-drawer-body {
  background-color: $gray-1;
}

#nprogress .spinner {
  display: none;
}
