@import 'src/components/kit/core/mixins.scss'; // import KIT mixins
@import 'src/components/cleanui/styles/mixins.scss'; // import CLEANUI mixins

// $text: #000;
// $success: green;

[data-kit-theme='default'] .ant-btn-icon-only.ant-btn-lg > * {
  font-size: 16px;
}

// .ant-table-tbody tr td
// {
//     text-align: center;
// }
